<template>
  <Popup :class="$style['form-order-user-data']">
    <PopupHeader
      :show-back-btn="true"
      @hide="$emit('hide')"
      @back="handleBack"
    >
      {{ t("forms.order_user_data.title") }}
    </PopupHeader>

    <BulldropForm
      v-if="productsStore.gameData?.resourcetype === 'BulldropGame'"
    />
    <MoogoldForm
      v-else-if="productsStore.gameData?.resourcetype === 'MooGoldGame'"
    />
    <StandoffForm
      v-if="productsStore.gameData?.resourcetype === 'StandoffGame'"
    />
    <PubgForm v-else-if="productsStore.gameData?.resourcetype === 'PUBGGame'" />
    <VoucherForm
      v-else-if="productsStore.gameData?.resourcetype === 'Voucher'"
    />
  </Popup>
</template>

<script lang="ts" setup>
import Popup from "~/components/Shared/Popup/Popup.vue";
import PopupHeader from "~/components/Shared/Popup/PopupHeader.vue";
import BulldropForm from "./FormOrderUserData/BulldropForm.vue";
import VoucherForm from "./FormOrderUserData/VoucherForm.vue";
import MoogoldForm from "./FormOrderUserData/MoogoldForm.vue";
import StandoffForm from "./FormOrderUserData/StandoffForm.vue";
import PubgForm from "./FormOrderUserData/PubgForm.vue";

const { t } = useI18n();
const productsStore = useProductsStore();

const handleBack = () => {
  productsStore.showUserDataForm = false;
  productsStore.showOrderConfirmForm = true;
};
</script>

<style lang="scss" module>
.form-order-user-data {
  @media screen and (max-width: $mobile) {
    padding-bottom: 120px;
  }
}
</style>
