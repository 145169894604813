<template>
  <!-- ID -->
  <SharedInputError v-if="isUserIdError">
    {{ t("errors.input_user_id") }}
  </SharedInputError>
  <Input
    v-model.trim="inputValueId"
    :has-error="isUserIdError"
    :placeholder="t('forms.order_user_data.input_id')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- MooGold server -->
  <Select
    v-if="productsStore.moogoldGameServers.length"
    :values="productsStore.moogoldGameServers"
    :not-selected-text="productsStore.moogoldGameServers[0].name"
    :class="$style['form-order-element']"
    :select-values-classes="[$style['form-order-select']]"
    @change="changedServers"
  />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="sendOrder"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import Select from "~/components/Shared/Select/Select.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const inputValueId = ref(null);
const inputValueMail = ref(userStore.userData?.email);
const gameServerId = ref<number>();

const isUserMailError = ref(false);
const isUserIdError = ref(false);
const isError = ref(false);
const errorText = ref<string | null>(null);

await productsStore.getMoogoldServersList(
  productsStore.gameData!.id.toString(),
);

const changedServers = async (data: number) => {
  gameServerId.value = data;
};

watch(
  () => productsStore.moogoldGameServers,
  () => {
    if (
      productsStore.moogoldGameServers &&
      productsStore.moogoldGameServers.length
    ) {
      gameServerId.value = productsStore.moogoldGameServers[0].value;
    }
  },
  { immediate: true },
);

const sendOrder = async () => {
  isUserMailError.value = !inputValueMail.value;
  isUserIdError.value = !inputValueId.value;

  if (isUserMailError.value || isUserIdError.value) return;
  try {
    await productsStore.orderMoogold({
      moogoldServer: gameServerId.value!,
      moogoldUserId: inputValueId.value!,
      email: userStore.userData?.email || inputValueMail.value!,
    });
  } catch (error: any) {
    if (error && error.data) {
      if (error.data.email) {
        errorText.value = error.data.email[0];
      } else if (error.response._data.moogold_user_id) {
        errorText.value = error.data.moogold_user_id[0];
      }
    } else {
      isError.value = true;
    }
  }
};
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-select {
  @media screen and (max-width: $mobile) {
    top: auto;
    bottom: 100%;
  }
}
</style>
