<template>
  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <div :class="$style['form-order-products']">
    <SharedActionCard
      v-for="product in products"
      v-bind:key="product.data.title"
      :class="$style['form-order-product']"
      :img="product.data.picture"
      :action="`${product.amount}x ${product.data.title}`"
      :caption="`${parseFloat(product.data.price) * product.amount} ₽`"
      size="xs"
      :with-arrow="false"
    />
  </div>
  <Hint />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="sendOrder"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";
import Hint from "./PubgForm/Hint.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const inputValueMail = ref(userStore.userData?.email);
const { gameData } = storeToRefs(productsStore);
const isUserMailError = ref(false);
const isError = ref(false);
const errorText = ref<string | null>(null);
const products = computed(() =>
  gameData.value?.products.filter((product) => product.amount > 0),
);
const sendOrder = async () => {
  isUserMailError.value = !inputValueMail.value;

  if (isUserMailError.value) return;
  try {
    await productsStore.orderPubg({
      email: inputValueMail.value!,
    });
  } catch (error: any) {
    if (error && error.data) {
      if (error.data.email) {
        errorText.value = error.data.email[0];
      } else if (error.data.pubg_uid) {
        errorText.value = error.data.pubg_uid[0];
      }
    } else {
      isError.value = true;
    }
  }
};
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-products {
  margin-bottom: var(--spacing-lg);
}
.form-order-product {
  margin-bottom: var(--spacing-2xs);
}
</style>
