<template>
  <!-- ID -->
  <SharedInputError v-if="isUserIdError">
    {{ t("errors.standoff_id") }}
  </SharedInputError>
  <Input
    v-model="inputValueId"
    :has-error="isUserIdError"
    :placeholder="t('forms.order_user_data.input_standoff_id')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />

  <SharedInputError v-if="isUserAvatarError">
    {{ t("errors.game_avatar") }}
  </SharedInputError>

  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />

  <p :class="$style['form-order-text']">
    {{ $t("forms.order_user_data.game_avatar") }}
  </p>
  <Uploader
    :has-limit="false"
    @change="imgChange"
  />

  <div>
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin") }}
    </p>
    <StandoffSkin
      v-if="settings"
      :src="settings?.skin_picture || ''"
      :description="settings?.skin_name || ''"
    />
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin_price") }}
    </p>
    <CopyField :value="skinPrice || 0" />
    <div style="margin-top: 10px">
      <GeneralError v-if="isError">
        {{ t("errors.order_error") }}
      </GeneralError>
      <GeneralError v-if="errorText">
        {{ errorText }}
      </GeneralError>
    </div>
  </div>

  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="sendOrder"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import Uploader from "~/components/Shared/Uploader/Uploader.vue";
import CopyField from "./StandoffForm/CopyField.vue";
import GeneralError from "./GeneralError.vue";
import type { StandoffOrderSettings } from "~/types/products";
import StandoffSkin from "./StandoffForm/StandoffSkin.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const inputValueId = ref(null);
const inputAvatar = ref<File | null>(null);
const inputValueMail = ref(userStore.userData?.email);

const settings = ref<StandoffOrderSettings>();
const surplus = Math.floor(Math.random() * 24) / 100;

// Cбрасываем ошибки
const isUserMailError = ref(false);
const isUserIdError = ref(false);
const isUserAvatarError = ref(false);
const isError = ref(false);
const errorText = ref<string | null>(null);
const sum = computed(() =>
  productsStore.gameData?.products.reduce(
    (res, { amount, data: { price } }) => res + amount * parseFloat(price),
    0,
  ),
);

const skinPrice = computed(() => {
  if (!sum.value || !settings.value?.rate || settings.value?.rate <= 0) {
    return 0;
  }

  const convertedWithdrawSum = sum.value / settings.value?.rate;
  const commission = settings.value.market_fee * (convertedWithdrawSum || 0);
  const skinPrice = useBankersRounding(
    convertedWithdrawSum + commission + surplus,
  );

  return skinPrice;
});

onBeforeMount(async () => {
  const data = await productsStore.orderStandoffSettings();
  if (data) {
    settings.value = data;
  }
});

const imgChange = (file: File) => (inputAvatar.value = file);

const sendOrder = async () => {
  isUserMailError.value = !inputValueMail.value;
  isUserIdError.value = !inputValueId.value;
  isUserAvatarError.value = !inputAvatar.value;

  if (isUserMailError.value || isUserIdError.value || isUserAvatarError.value)
    return;
  try {
    await productsStore.orderStandoff({
      gameAccountId: inputValueId.value!,
      gameAvatar: inputAvatar.value!,
      surplus: surplus!,
      email: userStore.userData?.email || inputValueMail.value!,
    });
  } catch (error: any) {
    if (error && error.data) {
      if (error.data.email) {
        errorText.value = error.data.email[0];
      } else if (error.data.game_account_id) {
        errorText.value = error.data.game_account_id[0];
      } else if (error.data.surplus) {
        errorText.value = error.data.surplus[0];
      } else if (error.data.game_avatar) {
        errorText.value = error.data.game_avatar[0];
      }
    } else {
      isError.value = true;
    }
  }
};
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-select {
  @media screen and (max-width: $mobile) {
    top: auto;
    bottom: 100%;
  }
}
.form-order-text {
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
}
</style>
